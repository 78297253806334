import { Transition } from '@headlessui/react';
import { useAppSelector } from '@store/index';
import { getPaymentData, getOrderStatus, getOrderData } from '@store/reducers/employer/subscription/subscriptionSelector';
import {
  StepSelectSubscription, StepPaySelectedPackage, StepNotEnoughCredits, StepPaymentStatus,
} from './flow-steps';


// Common transition for all steps
// *****************************************
const commonTransition = {
  enter: 'transition-all duration-300 delay-75',
  enterFrom: 'opacity-0 translate-y-2 min-h-96',
  enterTo: 'opacity-100 translate-y-0 min-h-max',
  leave: 'transition-all duration-300 absolute inset-0',
  leaveFrom: 'opacity-100 translate-y-0 min-h-max',
  leaveTo: 'opacity-0 translate-y-2 min-h-96 hidden',
};


/**
 * @description Renders the body for the payment modal in the Subscription flow. In this flow the
 * user can select a package and pay for it.
 *
 * @param closeModal Function to close the modal
 */
export const SubscriptionsHubModal = ({ closeModal }: { closeModal?: () => void }) => {
  // 1. Payment data shows the payment options and pricing.
  // 2. Order data shows the order data (after ordering in payment step).
  // 3. Order status shows the status of the order (paid ok or error).
  // *****************************************
  const paymentData = useAppSelector(getPaymentData);
  const orderData = useAppSelector(getOrderData);
  const orderStatus = useAppSelector(getOrderStatus);


  // Show conditions for each step
  // *****************************************
  const showStep = {
    chooseSubscription: !paymentData && !orderStatus && !orderData?.shoppingCartId,
    paymentOptions: paymentData && !orderStatus && !orderData?.shoppingCartId,
    buyCredits: orderData?.shoppingCartId,
    status: orderStatus,
  };


  // Render component
  // *****************************************
  return (
    <div className="relative min-h-96 bg-surface-100">

      {/* STEP 1: Choose a upgrade package
      ******************************************************** */}
      <Transition
        appear
        show={Boolean(showStep.chooseSubscription)}
        as="div"
        {...commonTransition}
      >
        <StepSelectSubscription />
      </Transition>

      { /* STEP 2: Payment options for the selected upgrade
      ******************************************************** */}
      <Transition
        appear
        show={Boolean(showStep.paymentOptions)}
        as="div"
        {...commonTransition}
      >
        <StepPaySelectedPackage paymentData={paymentData} />
      </Transition>

      { /* STEP 3: Not enough credits: the user chose to pay from the
           wallet but the credits are insufficient
      ******************************************************** */}
      <Transition
        appear
        show={Boolean(showStep.buyCredits)}
        as="div"
        {...commonTransition}
      >
        <StepNotEnoughCredits shoppingCartId={orderData?.shoppingCartId} />
      </Transition>

      { /* STEP 4: Show status if payment was done automatically - not redirected to Stripe or buy credits page -
           (step conditions: the user has a saved credit card or enough credits in wallet)
      ******************************************************** */}
      <Transition
        appear
        show={Boolean(showStep.status)}
        as="div"
        {...commonTransition}
      >
        <StepPaymentStatus
          orderStatus={orderStatus}
          packageName={paymentData?.paymentTitle}
          closeModal={closeModal}
        />
      </Transition>

    </div>
  );
};
