export const CvDetailExperiencePlaceholder = () => (
  <div className="mr-2 animate-pulse border-b-2 py-10">
    <div className="mb-2 h-5 w-48 rounded bg-slate-200" />
    <div className="mb-10 h-3 w-12 rounded bg-slate-200" />

    <div className="animate-pulse  border-b-2 " />

    <div className="flex items-start gap-5 pt-5">
      <div className="size-8 rounded  bg-slate-200" />
      <div className="w-full">
        <div className="mb-3 h-4 w-full rounded bg-slate-200" />
        <div className="mb-10 h-2 w-12 rounded bg-slate-200" />

        <div className="mb-2 h-2 w-full rounded bg-slate-200" />
        <div className="mb-2 h-2 w-full rounded bg-slate-200" />
        <div className="mb-2 h-2 w-full rounded bg-slate-200" />
        <div className="mb-0 h-2 w-full rounded bg-slate-200" />
      </div>
    </div>
  </div>
);
