export const CvDetailTabsPlaceholder = () => (
  <>
    <div className="mr-2 animate-pulse border-b-2 py-5">
      <div className="mt-1">
        <div className="flex items-center justify-between gap-3">
          <div className="mb-2 h-4 w-1/2 rounded bg-slate-200" />
          <div className="mb-2 h-4 w-1/2 rounded bg-slate-200" />
        </div>

      </div>
    </div>
    {[1, 2, 3].map((index) => (
      <div key={index} className="mr-2 flex animate-pulse items-center space-x-4 border-b-2 py-2">
        <div className="flex items-center">
          <div className="mx-auto mb-5 size-16 rounded-full bg-slate-200 md:mb-0 md:mr-2" />
          <div className="mx-auto mt-1 px-4">
            <div className="mb-2 h-2 w-24 rounded bg-slate-200" />
            <div className="mb-2 h-4 w-44 rounded bg-slate-200" />
          </div>
        </div>
      </div>
    ))}
  </>

);
