import { Label, RadioText } from '@components/common/Forms';
import { HandleCheckboxChangeFunction } from 'src/types/hooks-functions/common';


// Component interface
interface CvWorthShareProps {
  handleChange: HandleCheckboxChangeFunction,
  label: string,
}


// The checkbox & text label for sharing Cv-Worth with or without salary
// *******************************
export const CvWorthShare = (props: CvWorthShareProps) => {
  const { handleChange, label } = props;

  return (
    <div className="-mb-3 mt-6 border-t pt-3 text-center">
      <Label htmlFor="shareWithSalary" className="!mt-0 inline-flex !w-auto cursor-pointer justify-center py-2">
        <input type="checkbox" name="shareWithSalary" onChange={handleChange} />
        <RadioText className="pt-px text-sm">{label}</RadioText>
      </Label>
    </div>
  );
};
