import { useState, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import {
  Input, Label, Select,
} from '@components/common/Forms';
import { useSwrStaticPhoneCountries } from '@hooks/useSwrApi/endpoints/static';
import { RecordInterface } from '@type/form-types';
import { useSwrMutationCheckIdentifier } from '@hooks/useSwrApi/endpoints/company/useSwrMutationCheckIdentifier';

interface CustomerData {
  customer?: ApiSchema<'CompanyWrite'> | undefined;
  locale: Locale;
  record: RecordInterface;
}


export const OnboardForm = (props: CustomerData) => {
  const {
    customer: baseCustomer, locale, record,
  } = props;

  const [customer, setCustomer] = useState<ApiSchema<'CompanyWrite'>>(baseCustomer || {} as ApiSchema<'CompanyWrite'>);

  const { t } = useTranslation('common');

  const [countryCode, setCountryCode] = useState<string>(customer?.phone?.country?.countryCode || '40');
  const [regionCode, setRegionCode] = useState<string>(customer?.phone?.country?.regionCode || 'RO');
  const [phoneNumber, setPhoneNumber] = useState<string>(customer?.phone?.number || '');

  const { data: phonePrefixes } = useSwrStaticPhoneCountries(locale);

  const classes = {
    label: 'font-bold text-ink-medium ml-3',
    parent: '!mt-0 mb-3',
  };

  const [companyIdentifier, setCompanyIdentifier] = useState('');
  const { data: companyDataByIdentifier, trigger, error: identifierError } = useSwrMutationCheckIdentifier(locale, companyIdentifier);

  useEffect(() => {
    if (companyIdentifier !== '') {
      void trigger({});
    }
  }, [companyIdentifier, trigger]);

  useEffect(() => {
    if (companyDataByIdentifier
      && Object.keys(companyDataByIdentifier).length > 0
      && JSON.stringify(customer) === JSON.stringify(baseCustomer)) {
      setCustomer({
        ...customer,
        companyName: companyDataByIdentifier.name,
        locationName: companyDataByIdentifier.address,
        registrationNumber: companyDataByIdentifier.registrationNumber,
      });
    }
  }, [companyDataByIdentifier, customer, baseCustomer]);

  return (
    <>
      <div className="gap-x-3 md:grid md:grid-cols-2">
        <div className={`${classes.parent} ${(baseCustomer?.identifier?.trim() || '') !== '' ? 'hidden' : ''}`}>
          <Label
            description={t('page.company.billing.label.identifier')}
            descriptionClass={classes.label}
            required
          >
            <Input
              defaultValue={customer?.identifier || ''}
              type="text"
              onBlur={(e) => {
                setCompanyIdentifier(e.target.value);
              }}
              {...record('identifier', {})}
              minLength={6}
              required
            />
            {identifierError?.errors[0]?.message
              && <p className="mt-2 text-sm font-semibold text-danger">{identifierError?.errors[0]?.message}</p>}
            <p className="ml-3 mt-2 text-sm text-ink-medium">{t('billing.data.cui.tooltip')}</p>
          </Label>
        </div>

        <div className={`${classes.parent} ${(baseCustomer?.companyName?.trim() || '') !== '' ? 'hidden' : ''}`}>
          <Label
            descriptionClass={classes.label}
            description={t('page.company.billing.label.company.name')}
            required
          >
            <Input
              defaultValue={customer?.companyName}
              type="text"
              {...record('companyName', {})}
              required
            />
          </Label>
        </div>

        <div className={`${classes.parent} ${(baseCustomer?.locationName?.trim() || '') !== '' ? 'hidden' : ''}`}>
          <Label
            htmlFor="customer.address"
            descriptionClass={classes.label}
            description={t('order.billing.address')}
            required
          >
            <Input
              defaultValue={customer?.locationName || ''}
              type="text"
              {...record('locationName', {})}
              required
            />
          </Label>
        </div>

        <div className={`${classes.parent} ${(baseCustomer?.phone?.number?.trim() || '') !== '' ? 'hidden' : ''}`}>
          <Label
            htmlFor="customer.phone"
            description={t('page.company.onboard.form.phone.number')}
            descriptionClass={classes.label}
            required
          >
            <div className="grid grid-cols-5 gap-x-3">
              <div className="col-span-2">
                <Select
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                    setRegionCode(phonePrefixes?.find((item) => item.name === e.target.value)?.id || '');
                  }}
                  value={countryCode}
                  {...record('phone.country.countryCode', {})}
                >
                  {phonePrefixes && phonePrefixes.map((item) => (
                    <option key={item.id} value={item.name}>
                      {item.id}
                      {' '}
                      ( +
                      {item.name}
                      {' '}
                      )
                    </option>
                  ))}
                </Select>
                <Input
                  type="hidden"
                  value={regionCode}
                  {...record('phone.country.regionCode', {})}
                />
              </div>
              <div
                className="col-span-3"
              >
                <Input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  minLength={2}
                  {...record('phone.number', {})}
                  required
                />
              </div>
            </div>
            <Input
              type="hidden"
              value={`+${countryCode}${phoneNumber}`}
              {...record('phone', {})}
              required
            />
          </Label>
        </div>
      </div>

      <div className="gap-x-3 md:grid md:grid-cols-2">
        <div className={`${classes.parent} ${(baseCustomer?.lastName?.trim() || '') !== '' ? 'hidden' : ''}`}>
          <Label
            htmlFor="customer.lastName"
            descriptionClass={classes.label}
            description={t('order.billing.lastName')}
            required
          >
            <Input
              defaultValue={customer?.lastName || ''}
              type="text"
              {...record('lastName', {})}
              required
            />
          </Label>
        </div>

        <div className={`${classes.parent} ${(baseCustomer?.firstName?.trim() || '') !== '' ? 'hidden' : ''}`}>
          <Label
            htmlFor="customer.firstName"
            descriptionClass={classes.label}
            description={t('order.billing.firstName')}
            required
          >
            <Input
              defaultValue={customer?.firstName || ''}
              type="text"
              {...record('firstName', {})}
              required
            />
          </Label>
        </div>
      </div>
    </>

  );
};
