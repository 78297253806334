import { useTranslation } from 'next-i18next';
import { Button, Modal } from '@components/common';
import { useState } from 'react';
import { Textarea } from '@components/common/Forms';
import { useSwrBiaResumeEvaluationFeedback } from '@hooks/useSwrApi/endpoints/evaluation-feedback/useSwrBiaResumeEvaluationFeedback';

interface AiEvaluationFeedbackProps {
  isOpen: boolean,
  toggleModal: () => void,
  identifier: string,
  evaluation?: ApiSchema<'ResumeEvaluation'>,
  applicantEvaluation?: ApiSchema<'ApplicationEvaluation'>,
}

/**
 * @description Renders the BIA evaluation feedback modal
 */
export const AiEvaluationFeedbackModal = (props: AiEvaluationFeedbackProps) => {
  // Props destructuring
  const {
    isOpen,
    toggleModal,
    identifier,
    evaluation,
    applicantEvaluation,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  const [comment, setComment] = useState('');

  const evaluationObject = evaluation || applicantEvaluation;

  const { trigger: triggerEvaluationFeedback, isMutating: isMutatingEvaluationFeedback } = useSwrBiaResumeEvaluationFeedback(
    identifier,
    applicantEvaluation === null || applicantEvaluation === undefined,
    () => {
      toggleModal();
      if (evaluationObject) {
        evaluationObject.hasFeedback = true;
      }
      setComment('');
    },
  );

  const onSubmit = () => {
    void triggerEvaluationFeedback({
      comment,
    });
  };

  // Render component
  // *****************************************
  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleModal}
      size="md"
      header={t('bia.evaluation.feedback.modal.title')}
      body={(
        <div className="p-5">
          <p className="mb-5">{t('bia.evaluation.feedback.modal.label')}</p>
          <span className="font-bold">{t('bia.evaluation.feedback.textarea.label')}</span>
          <Textarea id="comment" value={comment} onChange={(e) => setComment(e.target.value)} rows={4} cols={50} />
          <div className="mt-5 flex justify-end text-right">
            <Button
              color="primary"
              size="sm"
              rounding="full"
              className="mr-2"
              onClick={() => onSubmit()}
              disabled={comment.length < 3}
              isLoading={isMutatingEvaluationFeedback}
            >
              {t('global.action.send')}
            </Button>
          </div>
        </div>
      )}
    />
  );
};
