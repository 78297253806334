import { Button } from '@components/common';
import { useTranslation } from 'next-i18next';

export const CustomConfirmModalContent = ({ onSendSuccess } : { onSendSuccess: (value:boolean) => void }) => {
  const { t } = useTranslation('common');

  return (
    <>
      <div className="p-5">
        <div className="mb-5 text-center">
          <span className="block text-xl font-bold">
            {t('cv.detail.contact.type')}
            <span className="text-base text-secondary">
              {` ${t('label-10-credits')}`}
            </span>
          </span>
          <span className="block text-sm text-ink-medium">{t('global.label.unlock.candidate.agree')}</span>
        </div>

        <div className="text-center">
          <Button role="presentation" size="sm" color="secondary" onClick={() => onSendSuccess(true)} className="mr-2">{t('global.label.unlock.candidate.info')}</Button>
          <Button role="presentation" size="sm" color="error" onClick={() => onSendSuccess(false)} className="">{t('global.label.unlock.candidate.deny')}</Button>
        </div>
      </div>

    </>
  );
};

