import React from 'react';
import { useTranslation } from 'next-i18next';
import {
  CvDetailInterface,
} from '../interface/CvDetailInterface';


export const ProfileInterview = ({ cvDetail }: CvDetailInterface) => {
  const { t } = useTranslation('common');

  return (
    <>
      { cvDetail?.application?.miniInterview
          && <h3 className="mb-3 text-lg font-bold">{t('cv.detail.mini_interview')}</h3>}
      { cvDetail?.application?.miniInterview && cvDetail?.application?.miniInterview.map((item:{ question:string;answer:string }) => (
        <div key={item.question} className="mb-4 border-b">
          <div className="mb-2 text-sm font-bold">{item.question}</div>
          <blockquote className="my-4 ml-1 border-l-4 border-gray-300 pl-4">
            <div className="my-3 text-sm font-normal">{item.answer}</div>
          </blockquote>
        </div>
      ))}
    </>
  );
};
