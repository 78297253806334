import {
  ChevronDownIcon, ChevronUpIcon,
} from '@heroicons/react/24/solid';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { sanitizeHtml } from '@utils/strings/sanitizeHtml';

interface IDescriptionProps {
  description: string;
  translated?: boolean;
}

export const ViewMoreText = ({ description = '', translated }: IDescriptionProps) => {
  const { t } = useTranslation('common');

  const [showMoreExperienceContent, setShowMoreExperienceContent] = useState<boolean>(false);

  const showMoreContent = () => {
    setShowMoreExperienceContent(!showMoreExperienceContent);
  };

  return (
    <>
      {showMoreExperienceContent && (
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml((description || '').replace(/\r?\n/g, '<br/>')),
          }}
        />
      )}

      {!showMoreExperienceContent && (
        <div
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(
              (typeof description === 'string' ? description.substring(0, 200) : '')
                .concat(description?.length > 200 ? '...' : '')
                .replace(/\r?\n/g, '<br/>'),
            ),
          }}
        />
      )}

      {description?.length > 200
            && (
            <div className="my-2 flex cursor-pointer items-center" role="presentation" onClick={showMoreContent}>
              { !showMoreExperienceContent && (
              <>
                <span className="mr-2 block min-w-fit text-sm text-primary">
                  {`${t('global.btn.see_more')} `}
                </span>
                <ChevronDownIcon className="inline-block size-5 text-primary" />
              </>
              ) }

              { showMoreExperienceContent && (
              <>
                <span className="mr-2 block min-w-fit text-sm text-primary">
                  {`${t('global.btn.see.less')} `}
                </span>
                <ChevronUpIcon className="inline-block size-5 text-primary" />
              </>
              ) }

              {translated
                  && <div className="w-full text-right text-xs">{t('global.label.bia.translated')}</div>}

            </div>
            )}

    </>
  );
};

