import { UserTalentInterest } from '@type/v1-api-types';
import {
  FolderPlusIcon, HeartIcon, CheckCircleIcon, UserPlusIcon, HandThumbUpIcon,
} from '@heroicons/react/24/outline';


/**
 * @description Applicant interest component
 * @param type - string - the type of interest (application, saved, like, suggestion, recommendation)
 * @param value - string - the value of interest
 */
export const ApplicantInterest = (props: UserTalentInterest) => {
  // Destructure props
  const {
    type,
    value,
  } = props;

  // common icons class
  const iconClass = 'w-4 h-4 shrink-0';

  // Render component
  // **********************************************
  return (
    <span className="flex shrink-0 items-center gap-1.5 text-xs">
      { /* Render icon based on type */
        type && (
          <>
            {type === 'application' && <CheckCircleIcon className={iconClass} />}
            {type === 'saved' && <FolderPlusIcon className={iconClass} />}
            {type === 'like' && <HeartIcon className={iconClass} />}
            {type === 'suggestion' && <UserPlusIcon className={iconClass} />}
            {type === 'recommendation' && <HandThumbUpIcon className={iconClass} />}
          </>
        )
      }

      {/* Render value */}
      {value}
    </span>
  );
};
