import { ApiUserResumeCommentOk } from '@type/v1-api-types/user';
import { useMemo, useState } from 'react';
import { Button } from '@components/common';
import { useTranslation } from 'next-i18next';
import { useSwrResumeEditComment } from '@hooks/useSwrApi/endpoints/resume-operations';


// Interface
interface EditCommentModalContentProps {
  locale?: string;
  editedComment: ApiUserResumeCommentOk;
  successCallback?: () => void;
  closeModalCallback?: () => void;
}


/**
 * @description Component to render the content of the edit comment modal.
 * * **locale** - the locale to use for the api calls. Defaults to 'ro'
 * * **editedComment** - the comment object to edit
 * * **successCallback** - callback to execute on success
 */
export const EditCommentModalContent = (props: EditCommentModalContentProps) => {
  // Destructure props
  const {
    locale = 'ro',
    editedComment,
    successCallback,
    closeModalCallback,
  } = props;

  const {
    id,
    commentBody = '',
  } = editedComment;

  // Translation
  const { t } = useTranslation('common');

  // States
  const [message, setMessage] = useState<string>(commentBody);
  const initialMessage = useMemo(() => commentBody, [commentBody]);


  // Handle success modification
  // ******************************************************
  const handleSuccessModification = () => {
    if (successCallback) successCallback();
    if (closeModalCallback) {
      setTimeout(() => {
        closeModalCallback();
      }, 500);
    }
  };


  // SWR: Edit comment
  // ******************************************************
  const {
    data: editedOk,
    trigger: editComment,
    isMutating: isSavingComment,
  } = useSwrResumeEditComment(locale, id, handleSuccessModification, t('cv.edit.comment.edit.success'));


  // Render component
  // ******************************************************
  return (
    <>
      <div className="p-3">
        <textarea
          className="mb-4 text-base"
          value={message}
          placeholder={t('cv.action.comment.edit')}
          onChange={(e) => { setMessage(e.target.value); }}
          rows={4}
        />

        <div className="text-center">
          <Button
            color="secondary"
            className="ml-1"
            size="sm"
            disabled={message === initialMessage || isSavingComment || editedOk !== undefined}
            isLoading={isSavingComment}
            onClick={() => { void editComment({ comment: message }); }}
          >
            {t('cv.action.comment.edit')}
          </Button>
        </div>
      </div>

    </>
  );
};
