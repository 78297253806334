import { PlusIcon, PencilIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useEffect, useState } from 'react';
import { OperationJob } from '@utils/data-fetching';
import { useTranslation } from 'next-i18next';
import { ComboBox, Input } from '@components/common/Forms';
import sendGoogleTagEvent from '@hooks/useSendGoogleTagEvent';
import { Button } from '@components/common';
import { clsx } from 'clsx';

// Interface
// ********************************************
export type JobFolderSelectorView = 'default' | 'select-job' | 'add-job';

interface JobFolderSelectorProps {
  selectedJobFolder?: OperationJob;
  sendJobFolderInfoToParent?: (item: OperationJob) => void | string;
  onChangeView?: (selectedView: JobFolderSelectorView) => void;
  className?: string;
  showPrice?: boolean;
}

/**
 * @description Component that can be used to select / add the current operation job folder for a talent.
 * The job folder can be selected from a list of existing jobs. Also, a new job folder can be added.
 * * **selectedJobFolder** - optional. the initial selected job folder.
 * * **sendJobFolderInfoToParent** - optional. callback to execute when a job folder is selected.
 * * **onChangeView** - optional. callback to execute when the current view changes.
 * * **className** - optional. additional classnames to be added to the component for styling purposes.
 */
export const JobFolderSelector = (props: JobFolderSelectorProps) => {
  // Props destructuring
  // ********************************************
  const {
    selectedJobFolder,
    sendJobFolderInfoToParent,
    onChangeView,
    className = '',
    showPrice = false,
  } = props;

  // Translation
  const { t } = useTranslation('common');

  const [currentFolderUnlock, setCurrentFolderUnlock] = useState<OperationJob | undefined>(selectedJobFolder);
  const [selectedView, setSelectedView] = useState<JobFolderSelectorView>('default');
  const [addNewJobInputValue, setAddNewJobInputValue] = useState<string>('');


  useEffect(() => {
    setCurrentFolderUnlock(selectedJobFolder);
  }, [selectedJobFolder]);

  useEffect(() => {
    if (onChangeView) {
      onChangeView(selectedView);
    }
  }, [selectedView, onChangeView]);

  const selectJobFolderValue = (item:OperationJob) => {
    sendGoogleTagEvent('employer_folder_status_changed', 'Custom');

    setSelectedView('default');
    setCurrentFolderUnlock(item);

    if (sendJobFolderInfoToParent) {
      sendJobFolderInfoToParent(item);
    }
  };

  const saveNewJobFolder = () => {
    const addNewJobValue: OperationJob = {
      title: addNewJobInputValue,
      jobId: 0,
      jobSlug: '',
    };
    selectJobFolderValue(addNewJobValue);
    if (sendJobFolderInfoToParent) {
      sendJobFolderInfoToParent(addNewJobValue);
    }
  };

  // Helper classes
  // ********************************************
  const buttonCommonClasses = 'shrink-0 !p-1 !w-10 !h-10';
  const buttonPrimaryClasses = '!bg-primary/10 !text-primary';
  const buttonSecondaryClasses = '!bg-secondary/10 !text-secondary';
  const buttonIconClasses = 'w-5 h-5';

  const defaultView = () => (
    <>
      <div className="flex-1 overflow-hidden">
        {currentFolderUnlock && (
          <span className="block text-base font-bold text-ink">
            {currentFolderUnlock?.title}
          </span>
        )}
        <span className={clsx('block', { 'text-sm text-ink-medium': currentFolderUnlock, 'text-ink': !currentFolderUnlock })}>
          {t('cv.actions.unlock.info')}
          {showPrice && ` ${t('cv.detail.contact_price')}`}
        </span>
      </div>
      <Button
        type="button"
        rounding="full"
        className={clsx(buttonCommonClasses, buttonSecondaryClasses)}
        onClick={() => setSelectedView('select-job')}
        title={t('global.action.edit')}
      >
        <PencilIcon className={buttonIconClasses} />
      </Button>
      <Button
        type="button"
        rounding="full"
        className={clsx(buttonCommonClasses, buttonPrimaryClasses)}
        title={t('global.action.add')}
        onClick={() => setSelectedView('add-job')}
      >
        <PlusIcon className={buttonIconClasses} />
      </Button>
    </>
  );

  const selectJobView = () => (
    <>
      <div className="relative flex-1">
        <ComboBox
          options={{
            freeSolo: true,
            valueFromKey: 'jobId',
            apiList: {
              fetchUrl: '/v1/job-operation/select-job',
              queryParams: (query: string) => ({ term: query }),
            },
            fetchDynamicListOnOpen: true,
          }}
          placeholder={t('folder.selector.placeholder')}
          renderList={(item, index, listKey, getItemProps, listStyling) => {
            const job = item as OperationJob;
            return (
              <li key={listKey} className={`${listStyling} text-sm`} {...getItemProps({ item, index })}>
                {job.title}
              </li>
            );
          }}
          onSelectedItemChange={(item) => {
            if (item) {
              selectJobFolderValue(item as OperationJob);
            }
          }}
        />
      </div>
      <Button
        type="button"
        rounding="full"
        color="white"
        className={buttonCommonClasses}
        title={t('global.action.cancel')}
        onClick={() => { setSelectedView('default'); }}
      >
        <XMarkIcon className={buttonIconClasses} />
      </Button>
    </>
  );

  const addJobView = () => (
    <>
      <div className="flex-1">
        <Input
          type="text"
          id="addNewJob"
          placeholder={t('global.job.title')}
          autoFocus
          value={addNewJobInputValue}
          onChange={(e) => setAddNewJobInputValue(e.target.value)}
        />
      </div>
      <Button
        type="button"
        rounding="full"
        className={clsx(buttonCommonClasses, buttonSecondaryClasses)}
        title={t('global.action.add')}
        onClick={saveNewJobFolder}
      >
        <PlusIcon className={buttonIconClasses} />
      </Button>
      <Button
        type="button"
        rounding="full"
        color="white"
        className={buttonCommonClasses}
        title={t('global.action.cancel')}
        onClick={() => { setAddNewJobInputValue(''); setSelectedView('default'); }}
      >
        <XMarkIcon className={buttonIconClasses} />
      </Button>
    </>
  );

  // Render component
  // ********************************************
  return (
    <div className={clsx('flex items-center justify-center gap-x-4', className)}>
      <div className="flex flex-1 items-center space-x-4">
        {/* We have different views based on the selected option: Default / Add New Job / Select existing job */}
        {selectedView === 'default' && defaultView()}
        {selectedView === 'add-job' && addJobView()}
        {selectedView === 'select-job' && selectJobView()}
      </div>
    </div>
  );
};
